<template>
  <div class="page-root">
    <div class="filter-tab">
      <el-form ref="filter" size="small" :model="filter" :rules="rules" label-width="10vh">
        <div style="width:calc(100% - 10vw);height:100%;display: flex;flex-direction: column;align-items: center;justify-content: space-around;">
          <el-row style="width:100%;" :gutter="10">
          <el-col :span="8">
            <el-form-item size="small" label="时间" prop="time">
              <el-date-picker style="width:18vw;" size="small" v-model="filter.time" type="daterange"
                :default-time="[new Date(2000, 1, 1, 0, 0, 0),new Date(2000, 1, 1,23, 59, 59),]" unlink-panels
                range-separator="To"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检测单位" prop="company">
              <!-- <el-select v-if="userRole=='org'" size="small" v-model="filter.company">
                <el-option-group v-for="[key, value] in Object.entries(userOrgs)" :label="key" :key="key">
                  <el-option v-for="org in value" :key="org.org_id" :label="org.org_name" :value="org.org_id">
                  </el-option>
                </el-option-group>
              </el-select> -->
              <el-select  placeholder="单位名查询" :loading="selectPoolLoading"  remote filterable
                             :remote-method="getSelectPool" v-model="filter.company">
                            <el-option v-for="auth in pool" :key="auth.id" :value="auth.id" :label="auth.name">
                            </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="结果" prop="result">
              <el-select size="small" v-model="filter.result" placeholder="选择检测结果">
                <el-option label="全部" :value="null" />
                <el-option label="合格" :value="1" />
                <el-option label="不合格" :value="2" />
                <el-option label="待定" :value="0" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="width:100%;" :gutter="10">
          <el-col :span="8">
            <el-form-item label="检测项目" prop="item">
              <el-select size="small" v-model="filter.item" placeholder="输入检测项目" clearable>
                <el-option v-for="item in checkItems" :key="item" :value="item" ></el-option>
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品名称" prop="sample">
              <el-input size="small" v-model="filter.sample"  clearable placeholder="输入样品种类"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检测员" prop="sample" >
              <el-input size="small" v-model="filter.checker" clearable placeholder="输入检测员"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        </div>
       
        <div style="width: 8vw;height:100%;margin-top:-1vh; flex: 0 0 8vw; display: flex;flex-direction: column;align-items: center;justify-content: space-around;">
              <el-button class="btn-search" type="primary" @click="dosearch()">
                <el-icon class="el-icon--left">
                  <search />
                </el-icon>查询
              </el-button>
              <el-button class="btn-reset" type="" size="small" @click="reset()"> 重置</el-button>
        </div>
      </el-form>
    </div>
    <div class="tool-bar">
      <div style="width:10vw;font-size:1.5vh;">
        <span>总计:{{totalNum}}</span>
      </div>
      <el-button size="small" type="danger" @click="setTimeout(deleteRecords(),20000)"
        :disabled="pageSelection.length<=0" :loading="deleting" :icon="Delete"></el-button>
      <el-button size="small" type="primary" @click="this.centerDialogVisible = true">
        <el-icon :size="30">
          <upload />
        </el-icon>
      </el-button>
      <el-button size="small" type="success" @click="saveToLocal()" :disabled="buttonActive">
        <el-icon :size="30">
          <download />
        </el-icon>
      </el-button>
    </div>
    <div class="table-wrapper table-area">
      <!-- 清除表格边框，修改高亮背景 -->
      <el-table ref="dataTable" style="--el-table-border-color:none;--el-table-row-hover-bg-color:#0d6c9f" :data="datatable"   height="62vh" :row-style="redCell"
        :row-class-name="addRowClass" :header-row-style="headerRowStyle" v-loading="loading" :header-cell-style="headercellstyle"
        row-key="check_record_id" @row-contextmenu="showDetails" @selection-change="handleSelect" >
        <el-table-column type="selection" width="40" :selectable="isSelectable" />
        <el-table-column label="No" type="index" :index="tableIndex" />
        <el-table-column prop="check_time" label="日期" :formatter="dateTranser" />
        <el-table-column prop="check_time" label="时间" :formatter="timeTranser" />
        <el-table-column prop="send_company" label="被检单位" />
        <el-table-column prop="company_name" label="检测单位" />
        <el-table-column prop="sample" label="样品" />
        <el-table-column prop="check_item" label="检测项目" />
        <el-table-column prop="ref_info" label="标准" />
        <el-table-column prop="check_value" label="检测值" width="70" />
        <el-table-column prop="checker" label="检测员" />
        <el-table-column prop="check_result" label="结果" :formatter="resultTranser" />
      </el-table>
      <el-pagination class="el-pagination" background layout="->,prev, pager, next" :total="totalNum" :page-size="pagesize" @current-change="changePage">
      </el-pagination>
    </div>
  </div>
  <el-dialog v-if="detailData !== undefined" ref="detailDialog" v-model="detailDialogVisible" :append-to-body="true"
    width="1150px" center top="3vh">
    <data-detail id="dataDetail" :data="detailData" />
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="printDetails()">打印</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog v-model="centerDialogVisible" title="从本地上传" width="30%" center @close="uploadResult=[]"
    :close-on-click-modal="false" :append-to-body="true">
    <div>
      <a @click="downloadtemplate()" style="color: green; text-decoration-line: underline">模板</a>
    </div>
    <div style="display: flex; flex-direction: row; align-items: flex-start">
      <input ref="uploadInput" type="file" accept=".xlsx" @input="readFile()" />
      &nbsp;&nbsp;
      <div v-if="this.uploadValid.wrong != 0" style="font-size: 8px">
        <span style="color: green">{{ uploadValid.right }}</span><span>通过;<span style="color: red">{{ uploadValid.wrong
        }}</span>失败</span>
      </div>
      <el-icon v-if="uploadValid.isAllValid" :color="uploadValid.isAllValid ? 'green' : 'gray'">
        <circle-check-filled />
      </el-icon>
    </div>

    <div v-for="(item, index) in uploadResult" :key="item"
      style="display: flex; flex-deriction: column; align-items: flex-start">
      {{ index }}:<span v-if="item instanceof Object">{{ item.message }}</span>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancelUpload()">取消</el-button>
        <el-button type="primary" @click="submitUpload()" :disabled="uploadButtonDisable">上传</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {
  Search,
  Edit,
  Delete,
  Upload,
  CircleCheckFilled,
  Download
} from "@element-plus/icons-vue";
import { getDataList, uploadData, deleteRecord } from "network/checkdata";
import {getOrgWithBindByArea,getOrgByName } from 'network/org'
import { download, upload } from "utils/xlsxUtils.js";
import DataDetail from "./DataDetail.vue";
import {translateData} from 'utils/constant.js'

import { getOrgDetail } from "network/org.js";
const xlsxHead = [
  "check_record_id",
  "sample",
  "sample_no",
  "check_item",
  "check_result",
  "check_time",
  "send_company",
  "ref_info",
  "check_value",
  "unit",
  "checker",
  "data_type",
]
const templateData = [
  {
    序号: "1",
    样品名称: "XX样品",
    样品编号: "YP123",
    检测项目: "XXX",
    检测结果: "合格",
    检测时间: new Date().toLocaleString(),
    送检单位: "XX单位",
    检测标准: "GBXX",
    检测值: "123",
    检测值单位: "%",
    检测人: "张三",
    数据类型: "1",
  }
]
export default {
  components: {
    DataDetail,
    Search,
    Edit,
    Delete,
    Upload,
    CircleCheckFilled,
    Download
  },
  methods: {
    addRowClass({ row, index }) {
      if (row.status === "2")
        return "deleted-data"
    },
    getSelectPool(query){
      if(query==undefined||query=="")
      return
      let role = this.userRole
      this.selectPoolLoading = true
      this.pool = []
                //查询前端缓存
                // let orgCache=this.getOrgCache
                // if(orgCache[query])
                //     {
                //         this.selectPoolLoading=false
                //         this.pool=orgCache[query].map(
                //             a=>{return {'id':a.orgId,'name':a.orgName}}
                //         )
                //     }
                getOrgByName(query).then(
                    res => {
                        this.selectPoolLoading = false
                        this.pool = res.map(a => { return { 'id': a.org_id, 'name': a.org_name } })
                    },
                    e => {
                        this.selectPoolLoading= false
                        this.$message.error(e.message)
                        console.log(e.message)
                    }
                )
      
    },

    isSelectable(row, index) {
      return row.status !== '2'
    },
    //处理选中
    handleSelect(selection) {
      if (this.pageSelection.length !== selection.length)
        this.pageSelection = selection
    },
    tableIndex(index) {
      return (this.currentpage - 1) * this.pagesize + 1 + index;
    },

    //删除记录
    async deleteRecords() {

      this.deleting = true
      let promise = []
      let selection = this.pageSelection
      let deleteResult = []
      selection.forEach(e => {
        promise.push(deleteRecord(e['check_record_id']).then(res => { return res }, e => { return e }))
      })

      await Promise.allSettled(promise).then(
        results => {
          for (let i in results) {
            deleteResult.push({ recordId: selection[i]['check_record_id'], res: results.status })
          }
        }
      )
      this.changePage(this.currentpage)
      this.deleting = false
    },

    setDefaultDate() {
      let firstdayofmounth = new Date()
      firstdayofmounth.setDate(1)
      this.filter.time = [firstdayofmounth, new Date()]
    },
    cancelUpload() {
      this.centerDialogVisible = false
      this.uploadValid = {
        isAllValid: false,
        right: 0,
        wrong: 0,
        wrongList: [],
      }
      this.uploadResult = []
      this.$refs.uploadInput.value = ''
    },
    readFile: function () {
      let file = this.$refs.uploadInput.files[0]
      let dataList = [];
      this.uploadValid = {
        isAllValid: false,
        right: 0,
        wrong: 0,
        wrongList: [],
      };
      upload(file, "快检数据", xlsxHead).then(
        (res) => {
          dataList = res;
          if (dataList === undefined || dataList.length === 0) { console.log(""); return; }

          //validate
          let notValidList = [];

          for (let i = 0; i < dataList.length; i++) {
            let date = Date.parse(dataList[i].check_time);
            if (!date) {
              notValidList.push({ id: i, message: "时间格式错误" });
              continue;
            } else dataList[i]["check_time"] = date;
            if (!/[0-2]{1}/.test(dataList[i]["check_result"])) {
              notValidList.push({
                id: i,
                message: "结果必须是 1:合格, 2:不合格",
              });
              continue;
            }
          }

          if (notValidList.length == 0) {
            this.uploadValid.isAllValid = true;
            this.uploadDataList = dataList;
            this.uploadButtonDisable = false;
          } else
            this.uploadValid.wrongList = notValidList;
          this.uploadValid.right = dataList.length - notValidList.length;
          this.uploadValid.wrong = notValidList.length;
        },
        (e) => {
          console.log("读取失败! " + e);
        }
      );
    },
    redCell: ({row,rowIndex }) => {
      let style = {
        height: "3vh",
        fontSize: "1.5vh",
        'background-color':'#06237a',
        border:'none',
        color:'#f2f2f2'
        //'background-color':'transparent'
      }
      if(rowIndex %2===0){
        style['background-color'] = '#013380'
      }
      if (row["check_result"] == 2)
        style.color = "red"
      if (row["status"] === "2") {
        style.textDecoration = "line-through"
      }
      return style
    },
    dateTranser: (row, column) => {
      let result = row[column.property];
      return new Date(result).toLocaleDateString();
    },
    timeTranser: (row, column) => {
      let result = row[column.property];
      return new Date(result).toLocaleTimeString();
    },
    resultTranser: function (row, column) {
      let result = row[column.property];
      switch (result) {
        case "1":
          return "合格";
        case "2":
          return "不合格";
        default:
          return "待定";
      }
    },
    showDetails: function (row, column, event) {
      event.preventDefault();
      this.detailData = row;
      this.detailDialogVisible = true;
    },
    printDetails() {
      let doc = document.querySelector("#dataDetail").outerHTML;
      if (doc == undefined) {
        this.$message.error("print fail");
        return;
      }
      let win,
        iframedoc,
        iframe = document.createElement("iframe"),
        f = document.body.appendChild(iframe);
      iframe.onload = () => {
        try {
          setTimeout(function () {
            win.focus();
            try {
              if (!win.document.execCommand("print", false, null)) {
                win.print();
              }
            } catch (e) {
              win.print();
            }
            win.close();
          }, 100);
        } catch (e) {
          console.log("error:" + e);
        }

        setTimeout(function () {
          document.body.removeChild(iframe);
        }, 1000);
      };
      win = f.contentWindow || f.contentDocument;
      iframedoc = f.contentDocument || f.contentWindow.document;
      iframedoc.open();
      iframedoc.write(doc);
      iframedoc.close();
    },
    getCompany() {
      this.userAuth;
    },
    reset() {
      this.$refs.filter.resetFields();
    },
    //查询
    dosearch() {
      let filter = this.$refs.filter;
      if (filter)
        filter.validate((valid) => {
          if (valid) {
            this.loading = true;
            this.currentpage = 1;
            this.pagesize = 20;
            this.List = [];
            getDataList(
              this.filter.time,
              this.filter.result,
              this.currentpage,
              this.filter.company,
              this.pagesize,
              this.filter.status,
              this.filter.checker,
              this.filter.item,
              this.filter.sample
            ).then(
              (res) => {
                this.totalpage = res.data.pages;
                this.datatable = res.data.dataList;
                this.currentpage = res.data.pageNum;
                this.pagesize = res.data.pageSize;
                this.totalNum = res.data.total;
                this.loading = false;
                this.filterCache = this.filter;
                this.buttonActive = false;
              },
              (err) => {
                this.$message.error("查询失败 " + err.message);
                this.loading = false;
              }
            );
          } else {
            this.$message.warning("请检查筛选条件");
          }
        });
    },
    //
    changePage: function (page) {
      this.pageSelection = []
      if (this.filterCache == null) return;
      this.loading = true;
      getDataList(
        this.filterCache.time,
        this.filterCache.result,
        page,
        this.filterCache.company,
        this.pagesize,
        this.filterCache.status,
        this.filter.checker,
        this.filter.item,
        this.filter.sample
      ).then(
        (res) => {
          this.datatable = res.data.dataList;
          this.loading = false;
          this.currentpage = page;
        },
        (err) => {
          this.loading = false;
        }
      );
    },
    downloadtemplate() {
      setTimeout(download(templateData, "moban", "快检数据"), 2000);
    },
    handleUploadSuccess() { },
    beforeUploadUpload() { },
    saveToLocal() {
      this.buttonActive = true;
      if (this.datatable.length == 0) this.dosearch();
      let dataList = [];
      getDataList(
        this.filter.time,
        this.filter.result,
        1,
        this.filter.company,
        this.totalNum,
        this.filter.status,
        this.filter.checker,
        this.filter.item,
        this.filter.sample
      ).then(
        (res) => {
          dataList = res.data.dataList;
          console.log('dataList',dataList)
          if (!dataList) return;
          const data = translateData(dataList)
          setTimeout(download(data, "快检数据", "快检数据"), 100);
        },
        (err) => {
          this.$message.error("获取数据失败");
        }
      );
    },
    submitUpload() {
      if (this.uploadDataList.length == 0 && this.uploadDataList == null) {
        this.$message.warning("没有数据要上传，请检查文件");
        return;
      }
      //上传数据
      let upload = [];
      try {
        for (let data of this.uploadDataList) {
          upload.push(
            uploadData(data).then(
              (res) => {
                return true;
              },
              (err) => {
                let result = { message: "未知上传错误" };
                switch (err.response.status) {
                  case 406:
                    result.message = "无权上传该公司数据";
                }
                return result;
              }
            )
          );
        }
        Promise.all(upload).then((values) => {
          this.uploadResult = values;
          console.log(this.uploadResult);
        });
      } catch (e) {
        console.log(e);
      }

      // this.centerDialogVisible = false;
    },
    prepare() {
      if (this.userOrgs == {}) return;
      let orgList;
      if (this.userRole == "org")
        if (this.userOrgs == {} || this.userOrgs == undefined)
          getOrgDetail(this.userAuth).then((res) => {
            orgList = res.data;
            this.$store.dispatch("companies/initCompany", orgList).then(
              (res) => {
                console.log(this.userOrgs);
              },
              (rej) => {
                console.log(rej.message);
              }
            );
          });
      if (this.userRole == "gov") {
      }
    },
  },
  computed: {
    userAuth() {
      return this.$store.getters.getUserAuth;
    },
    userRole() {
      return this.$store.getters.getUserRole;
    },
    userOrgs() {
      return this.$store.getters["companies/userCompanies"];
    },
    checkItems(){
      return this.$store.getters["checkItems/checkItemList"]
    }
  },
  data() {
    return {
      Delete,
      filter: {
        time: [],
        company: "",
        sample: "",
        item: "",
        checker: "",
        result: "",
        status: "1",
      },
      buttonActive: true,
      deleting: false,
      filterCache: {},
      selectPoolLoading:false,
      pool: [],
      authCache: [],
      rules: {
        time: [
          {
            required: true,
            message: "时间不为空",
            trigger: "change",
          },
        ],
      },
      pageHeight: 0,
      currentpage: 1,
      pagesize: 20,
      List: [],
      datatable: [],
      pageSelection: [],
      selection: [],
      totalNum: 0,
      headerRowStyle: {
        "font-size": "1.5vh",
        height: "2vh",
        "line-height": "2vh",
        color: "black",
        border: "1px solid black",
        'background-color':"#165191"
      },
      headercellstyle:{
        'background-color':"#165191"
      },
      uploadDataList: [],
      uploadValid: {
        isAllValid: false,
        right: 0,
        wrong: 0,
        wrongList: [],
      },
      uploadResult: [],
      loading: false,
      centerDialogVisible: false,
      uploadButtonDisable: true,
      detailDialogVisible: false,
      detailData: {},
    };
  },
  created() {
    this.setDefaultDate()
  },
  mounted() {
    this.prepare();
  },
};
</script>

<style lang="less" scoped>
 
.page-root {
  width: 90vw;
  height: 86vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #071f63;
}

.tool-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: calc(100% - 5vw);
  height: 4vh;
  margin: 2vh 0;
  color: #f2f2f2;

  .el-button {
    height: 3vh;
    font-size: 1.5vh;
  }
}

.filter-tab {
  background-color: #06237a;
  width: calc(100% - 5vw);
  flex: 0 0 7vh;
  border: 0.5vh solid var(--el-border-color-base);
  display: flex;
  align-items: center;
  margin-top: 2vh;
  padding-top: 1vh;
  justify-content: space-around;
  border-radius: 8px;

  .el-form {
    width: 100%;
    height: 100%;
    display: flex; flex-direction: row;
    align-items: center;
    justify-content: space-around;

    .el-row {
      flex: 0 0 50%;
      align-items: center;
      justify-content: center;
    }

    .el-form-item {
      height: 3vh;
      line-height: 3vh;
      --font-size: 2vh;
      --el-form-label-font-size: var(--font-size);

      .el-button {
        height: 3vh;
        font-size: 1.5vh;
        width: 4vw;
      }

      .el-input {
        height: 3vh;

        .el-input__inner {
          height: 3vh;
        }
      }
    }
  }
}

.table-wrapper {
  width: calc(100% - 4.5vw);
  height: 67vh;
  flex: 0 0 68vh;
  border: 0.5vh solid var(--el-border-color-base);
  .el-table {
    width: 100%;
    // background-color: transparent; //表格透明
  }

  

  .el-pagination {
    height: 3vh;
  }
}

//表格样式
.table-area /deep/ .el-table__body-wrapper {
  background-color:#06237a ;
}

.filter-tab /deep/ .el-form-item__label {
  color: #f2f2f2;
  font-size: 1.5vh;
  line-height: 3vh;
}
//输入框样式
.filter-tab /deep/ .el-input__wrapper {
  height: 3.2vh;
  background-color: #0c59b9;
  color: #f2f2f2 !important;
  box-shadow: none;
}
.filter-tab /deep/ input {
  color: #f2f2f2;
}
//按钮样式
.btn-search {
  width: 6.5vh;
  height: 3vh;
  line-height: 3vh;
  background-color: transparent;
  border: 1px solid #1763FF;
  border-radius: 5px;
}
.btn-reset {
  width:6.5vh;
  height: 3vh;
  line-height: 3vh;
  background-color: transparent;
  border: 1px solid #1763FF;
  color: white;
  margin-left: 0;
  border-radius: 5px;
}

.el-pagination {
  padding: 1vh;
}

</style>
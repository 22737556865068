
const datalist = {

    check_item
        :
        "检测项目",
    check_result
        :
        "检测结果", //转中文
    check_time
        :
        '检测时间', //转中文
    check_value
        :
        "检测值",
    checker
        :
        "检测员",
    company_name
        :
        "检测单位",
    place_of_origin
        :
        "产地",
    ref_info
        :
        "标准",
    sample
        :
        "检测样品",
    send_company
        :
        "被检单位",
}

const resultTranser = (result)=>{
    switch (result) {
        case "1":
          return "合格";
        case "2":
          return "不合格";
        default:
          return "待定";
      }
}
const timeTranser = (time) => {
    return new Date(time).toLocaleDateString() + " " + new Date(time).toLocaleTimeString();
  }

const correctData = num =>{
    return num + '%'
}

export const translateData = (origin)=>{
    return origin.reduce((pre,item,index)=>{
        //英中转换
        item.check_result = resultTranser(item.check_result)
        item.check_time = timeTranser(item.check_time)
        item.check_value = correctData( item.check_value)
        
        const newItem = {}
        for(let key in datalist){
            newItem[datalist[key]] = item[key]
        }
        pre.push(newItem)
        return pre
    },[])
}

